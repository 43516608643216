html {
	background: #337ab7;
	height: 100%;
}

html, body {
	margin: 0;
	padding: 0;
}

body {
	font-family: "San Francisco", "Helvetica Neue", "Helvetica", "Arial";
}

a {
	color: #00a4ca;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

img {
	width: 100%;
}

h1 strong, h2 strong {
	font-weight: 700;
}

h1 {
	font-weight: 300;
	font-size: 2.3em;
	margin: 0;
}

h2 {
	font-weight: 300;
	font-size: 2.2em;
	margin: 0 0 30px 0;
}

h3 {
	margin: 20px 0 10px 0;
}


p, address {
	font-size: 1.38em;
	color: #666;
	margin-bottom: 20px;
	font-weight: 300;
	line-height: 1.4em;
}

ol {
	font-size: 1.38em;
	color: #666;
	margin-bottom: 20px;
	font-weight: 300;
	line-height: 1.4em;
	text-align: justify;
    counter-reset: item;
}
ol > li {
  counter-increment: item;
}
ol ol > li {
  display: block;
}
ol ol > li:before {
  content: counters(item, ".") " ";
}

button {
	color: #fff;
	text-decoration: none;
	padding: 5px 15px;
	background: $brand-color;
	border-radius: 3px;
	border: 1px solid rgba(255,255,255,.5);
	transition: .2s ease-in-out;
}

button:hover {
	border: 1px solid #ddd;
	background: $secondary-brand-color;
	cursor: pointer;
}

table {
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  width: 100%;
  max-width: 100%;
  margin-bottom: 23px;
}
